<script setup lang="ts">
import { useStorage } from "@vueuse/core";

const { t } = useT();
const { open, close, isOpen } = useFunrizeModals();
const { isSweepStakes } = useSwitchMode();
const localPath = useLocalePath();
const { maxReward, hasDailyReward, statusActivateReward, claimIsActive, durationLeft, claimWeeklyReward } =
	useDailyPlayback();
const isCashbackCalculation = useStorage<boolean>("isCashbackCalculation", false);

const handleClick = () => {
	if (isCashbackCalculation.value && hasDailyReward.value) {
		return;
	}

	if (isOpen("LazyOModalDailyPlaybackPromo")) {
		close("LazyOModalDailyPlaybackPromo");
	}

	if (!hasDailyReward.value) {
		open("LazyOModalDailyPlaybackRules");
		return;
	}

	if (claimIsActive.value) {
		claimWeeklyReward();
		return;
	}

	navigateTo(localPath("/issues/all-games/"));
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 656,
			height: 514,
			src: '/nuxt-img/playback/promo.webp',
			loading: 'lazy',
			alt: 'card-playback'
		}"
		height="257px"
		@click="handleClick"
	>
		<template #default>
			<ABadge background="var(--carrara)" variant="skew" class="top-left" autosize>
				<AText :size="12" :modifiers="['bold', 'uppercase']">{{ t("playBack.promo.badge") }}</AText>
			</ABadge>
		</template>
		<template #top>
			<div class="block">
				<AText :size="10" :modifiers="['normal', 'uppercase']" class="title">
					{{ t("playBack.title") }}
				</AText>
				<AText :size="12" :modifiers="['bold', 'uppercase']" class="sub-title">
					{{ t("playBack.promo.subtitle") }}
				</AText>
				<MPrizeFund
					:variant="isSweepStakes ? 'entries' : 'coins'"
					:icon="isSweepStakes ? '20/entries' : '16/coins'"
					:icon-size="isSweepStakes ? 38 : 28"
				>
					<AText :size="32" :class="`text-${isSweepStakes ? 'cixi' : 'chittagong'}`" :modifiers="['black']">
						{{ isSweepStakes ? numberFormat(maxReward?.entries || 0) : numberFormat(maxReward?.coins || 0) }}
					</AText>
				</MPrizeFund>
				<AText v-if="!hasDailyReward" :size="12" :modifiers="['normal']">
					<i18n-t keypath="playBack.promo.unlock.level">
						<template #icon>
							<NuxtImg
								class="icon-level"
								:src="`/nuxt-img/status-system/slides/${statusActivateReward?.toLocaleLowerCase()}.png`"
								format="webp"
								alt="playback-level"
								width="300"
								height="266"
								loading="lazy"
							/>
						</template>
						<template #level>
							<b>{{ t("playBack.promo.unlock.status", { level: statusActivateReward }) }}</b>
						</template>
					</i18n-t>
				</AText>
				<AText v-else-if="claimIsActive" :size="12" class="text-cixi text" :modifiers="['normal']">
					{{ t("playBack.promo.txt.reward") }}
				</AText>
				<AText v-else-if="isCashbackCalculation" :size="12" class="text-cannes text">
					{{ t("playBack.txt.calc") }}
				</AText>
				<AText v-else :size="12" class="text" :modifiers="['normal']">
					<i18n-t keypath="playBack.promo.timer">
						<template #time>
							<b>{{ durationLeft }}</b>
						</template>
					</i18n-t>
				</AText>
			</div>
		</template>
		<template #bottom>
			<AButton v-if="!hasDailyReward" variant="outline">
				<AText :size="16" :modifiers="['bold', 'uppercase']">{{ t("playBack.account.rules") }}</AText>
			</AButton>
			<AButton v-else variant="primary" :class="{ 'promo-disabled': isCashbackCalculation }">
				<AText :size="16" :modifiers="['semibold', 'uppercase']">
					{{ claimIsActive ? t("playBack.promo.btn.claim") : t("playBack.promo.btn.play") }}
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card-count {
	display: flex;
	align-items: center;
	gap: 4px;
}

.top-left {
	position: absolute;
	top: 0;
	left: 0;
}

button {
	width: 240px;
	height: 40px;
	gap: 6px;
}

.card {
	&:deep(.top),
	&:deep(.bottom) {
		align-items: flex-start;
	}

	&.calc {
		cursor: not-allowed;
	}

	.time {
		position: absolute;
		top: 42px;
		left: 20px;
		max-width: 100%;
	}

	.title {
		position: absolute;
		top: 42px;
		left: 20px;
		max-width: 100%;
	}

	.sub-title {
		display: block;
		min-height: 15px;
		margin-bottom: 4px;
	}

	.block {
		display: flex;
		flex-direction: column;
	}

	.icon-level {
		width: 30px;
		height: 26px;
		transform: translateY(8px);
	}

	.text {
		margin-top: 14px;
	}

	.promo-disabled {
		cursor: not-allowed;
		opacity: 0.6;
	}
}
</style>
